<template>
  <div id="external" :class="[options.controllerBackground ? 'hasBackground' : 'hasNotBackground']">
    <!-- HOW TO PLAY -->
    <section class="section p-0" v-if="(serverState < 2 && options.queue.enabled)">
      <div class="waiting is-flex is-flex-direction-column has-background-white-ter has-text-black-bis">
        <div class="top p-5">
          <div class="mb-5 has-text-weight-bold">{{ $t('exp_external.howToPlay') }}</div>
          <div v-if="options.tutorial.animation" class="tutorial p-5 is-flex is-justify-content-center">
            <img :src="options.tutorial.animation.url" alt="How to play" class="image" />
          </div>
          <p v-if="options.tutorial.text" class="mt-2">{{ options.tutorial.text }}</p>
        </div>
        <div v-if="moreOnePlayer" class="bottom is-flex is-align-items-center is-justify-content-center">
          <span class="is-size-5">{{ $t('exp_external.waitingPlayers') }}</span>
        </div>
      </div>
    </section>
    <!-- GAME STARTED -->
    <section class="section playing has-background-white-ter controllerContainer p-0"
      v-if="(serverState == 2 || (!options.queue.enabled && serverState != 3))"
      :style="{ backgroundImage: bgControllerUrl }">
      <!-- JOYSTICK -->
      <div v-if="options.controller.type == 'joystick'" class="joystick-wrapper"
        style="background-image: url(/assets/external/circles.svg)">
        <JoyStick @change="UpdateJoystick($event)" :debug="ShowDebugger"
          :isCircular="options.controller.circularJoystick" :buttons="options.controller.buttonsJoystick" />
      </div>
      <!-- GYRO -->
      <div v-if="options.controller.type == 'gyro'" class="gyro-wrapper">
        <gyro-access @moved="UpdateGyro" @callibrated="callibrated" v-slot="gyro" ref="myGyro">
          <pre v-if="options.others.debugValues" class="mb-4 p-2">{{ gyro }}</pre>
          <div v-if="gyro.noGyro || (!gyro.dataStarted && gyro.DeviceOrientationEnabled)"
            class="is-flex is-flex-direction-column requestGyro">
            <h3 class="title is-6">{{ $t('exp_infographic.startInteraction') }}</h3>
            <div class="tutorial is-flex is-justify-content-center mb-5">
              <img src="/assets/external/gyro-startinteracting.png" />
            </div>
            <div class="is-flex is-justify-content-center">
              <b-button :label="$t('exp_infographic.activateSensors')" expanded type="is-primary" size="is-medium"
                @click="$refs.myGyro.RequestGyro()" />
            </div>
          </div>
        </gyro-access>
      </div>
      <!-- Left/Right -->
      <div v-if="options.controller.type == 'leftRight'" class="left-right-wrapper">
        <LeftRight @change="UpdateLeftRight($event)" :debug="ShowDebugger"/>
      </div>

    </section>
    <!-- GAME FINISHED -->
    <section v-if="(serverState == 3)" :style="{ backgroundImage: bgControllerUrl }"
      class="match-finished section p-5">
      <!-- Scoreboard -->
      <div class="is-flex is-flex-direction-column is-align-content-center is-justify-content-start has-text-centered is-align-items-center ">
        <template v-if="!hasQueue" >
        
          <img v-if="options.finalScreen.finalImage"
            :src="encodeURI(options.finalScreen.finalImage.url)"
            class="final-image"/>
          <div v-if="options.finalScreen.finalText"
            class="is-size-5">{{ options.finalScreen.finalText }}
          </div>
          <div v-if="options.finalScreen.finalTextSubtitle"
            class="is-size-6">{{ options.finalScreen.finalTextSubtitle }}
          </div>
        </template>
        <template v-else-if="result.win">
          <div class="is-size-2">
            <!-- check si gano -->
            <svg class="win" viewBox="0 0 512 512">
              <path
                d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
            </svg>
          </div>
          <div class="is-size-3 has-text-weight-bold">
            {{ $t('exp_external.youWin') }}
          </div>
          <div v-if="options.finalScreen.winText" class="is-size-5">{{ options.finalScreen.winText }}</div>
        </template>
        <template v-else-if="result.win != undefined">
          <div class="is-size-2">
            <!-- cruz si perdio -->
            <svg class="lose" viewBox="0 0 320 512">
              <path
                d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
            </svg>
          </div>
          <div class="is-size-3 has-text-weight-bold">
            {{ $t('exp_external.youLost') }}
          </div>
          <div v-if="options.finalScreen.lostText" class="is-size-5">{{ options.finalScreen.lostText }}</div>
        </template>
        <template v-else>
          <div class="is-size-2">
            <svg class="tied" viewBox="0 0 320 512">
              <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
            </svg>
          </div>
          <div class="is-size-3 has-text-weight-bold">
            {{ $t('exp_external.youTied') }}
          </div>
          <div v-if="options.finalScreen.tiedText" class="is-size-5">{{ options.finalScreen.tiedText }}</div>
        </template>

      </div>
      <div class="backToMenuWrapper auto-height">
        <b-button type="is-primary" :label="$t('system.backToMenu')" expanded @click="$router.push('/')" />
      </div>
    </section>
  </div>
</template>
    
<script>
import GyroAccess from "../GyroAccess"
import JoyStick from "../externals/JoyStick"
import LeftRight from "../externals/LeftRight"

export default {
  components: {
    JoyStick,
    GyroAccess,
    LeftRight
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      EmitCoolingDown: false,
      EmitCooldownTime: 50, // ms
      joystickData: {
        x: 0,
        y: 0,
        speed: 0,
        angle: 0,
        radians: 0,
      },
      result: {
        win: false,
        gameTime: ''
      }
    }
  },

  computed: {
    hasQueue() {
      return this.$parent.room.experience.component[0].queue.enabled
    },
    serverState() {
      return this.$parent.serverState
    },
    moreOnePlayer() {
      return this.$parent.room.experience.component[0].queue.maxPlayers > 1
    },
    bgControllerUrl() {
      return this.options.controllerBackground ?
        'url(' + encodeURI(
          this.options.controllerBackground.sizes.mobile.url ?
            this.options.controllerBackground.sizes.mobile.url :
            this.options.controllerBackground.url) + ')'
        : ''
    },
    ShowDebugger() {
      return !this.$store.state.setup.others?.hideDebugger
    },
  },
  methods: {
    UpdateGyro(data) {
      if (this.EmitCoolingDown) return // cooldown
      this.$socket.client.emit('gameMessage', {
        type: 'GetGyroData',
        x: data.coords.x,
        y: data.coords.y
      });
      console.log({ x: data.coords.x, y: data.coords.y })
      this.EmitCoolingDown = true;
      setTimeout(() => {
        this.EmitCoolingDown = false
      }, this.EmitCooldownTime)
    },
    callibrated() {
      this.$socket.client.emit('gameMessage', {
        type: 'GyroCallibrated'
      })
      this.$sfxPlay('resetthecenter')
    },
    UpdateJoystick({ x, y, speed, angle, radians }) {
      //Copia los datos del joystick del componente hijo
      const joy = this.joystickData;
      joy.x = x;
      joy.y = y;
      joy.speed = speed;
      joy.angle = angle;
      joy.radians = radians;

      // Envia los datos a la screen
      if (this.EmitCoolingDown) return  // cooldown
      this.$socket.client.emit('gameMessage', {
        type: 'GetJoystickData', // me gustaria cambiar esto a 'pos' para que sea igual al gyro y otros joysticks
        x: joy.x,
        y: joy.y,
        speed: joy.speed,
        angle: joy.angle,
        radians: joy.radians
      });
      this.EmitCoolingDown = true;
      setTimeout(() => {
        this.EmitCoolingDown = false
      }, this.EmitCooldownTime)
    },
    UpdateLeftRight(data){ 

      console.log(data)
      this.$socket.client.emit('gameMessage', {
        type: 'UpdateLeftRight',
        direction: data.direction
      });
    }
  },

  mounted() {
    //
  },

  watch: {

  },

  sockets: {
    showResult(data) {
      console.log(data)
      console.log(data.winner)
      console.log(data.gameTime)

      if(this.hasQueue){
        this.result.win = data.winner;
        let minutes = Math.floor(data.gameTime / 60)
        let seconds = data.gameTime - (minutes * 60)
        if (minutes < 10) minutes = "0" + minutes
        if (seconds < 10) seconds = "0" + seconds
        this.result.gameTime = minutes + ':' + Math.floor(Math.floor(seconds))

        if(this.result.win){
          this.$sfxPlay('winnerscreen')
        } else if(typeof this.result.win != undefined) {
          this.$sfxPlay('loserscreen')
        } else {
          this.$sfxPlay('tiescreen')
        }
        
      } else {
        this.$sfxPlay('finalscreen')
      }
      
    },
  },
};
</script>
  
<style scoped lang="scss">
@import '@/styles/variables.scss';

section {
  height: 100%;
}

.playing {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;

  .joystick-wrapper {
    width: 86vw;
    padding: 11%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .left-right-wrapper {
    background-color: #DBDBDB;
    position: relative;
    width: 100%;
    height: 100%;
  }

}

.auto-height {
  height: auto !important;
}

.tutorial img {
  height: 250px;
}

.waiting {
  height: 100%;

  .top {
    height: 100%;
    line-height: 1;
  }

  .bottom {
    background-color: $trivia-waiting-players-background;
    color: $trivia-footer-color;
    height: 93px;
  }

}

.match-finished {
  background-size: cover;

  &>div {
    height: 100%;
    gap: 3em;
    padding-top: 4em;
  }

  .final-image {
    height: 7rem;
  }
  
  svg {
    height: 4rem;
  }

  .lose {
    fill: $trivia-danger;
  }

  .tied {
    fill: $trivia-alert;
  }
}

.hasBackground {

  .match-finished {
    color: $white-ter;

    .win {
      fill: $white-ter;
    }

  }

}

.hasNotBackground {

  .match-finished {
    color: $black-ter;

    .win {
      fill: $trivia-sucess;
    }

  }

}
</style>
  
  